// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const ExpressRewardsCheckoutShopPage = async () => import(
    /* webpackChunkName: 'express-rewards-checkout-shop' */
    '../views/ExpressRewardsCheckoutShopPage.vue'
);

export default [
    {
        path: '/checkout_shop_not_needed',
        component: ExpressRewardsCheckoutShopPage,
        meta: { public: true },
    },
    {
        path: '/checkout_shop_confirm',
        component: ExpressRewardsCheckoutShopPage,
        meta: { public: true },
    },
    {
        path: '/checkout_shop/:submissionId',
        component: ExpressRewardsCheckoutShopPage,
        props: true,
        meta: { public: true },
    },
    {
        path: '/express_rewards_checkout_shop',
        component: ExpressRewardsCheckoutShopPage,
        meta: { public: true },
    },
];
