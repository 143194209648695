import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal non-participating",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["alt"]
const _hoisted_6 = {
  key: 1,
  class: "fal fa-times"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay general-modal",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString($setup.t('non_participating.headline_copy')), 1),
      (_ctx.isDiamondPlus)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "non-participating-copy",
            innerHTML: $setup.t('non_participating.dp_body_copy')
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.isDiamondPlus)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: $setup.t('non_participating.body_copy')
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({ 'non-participating-disclaimer': _ctx.isDiamondPlus }),
        innerHTML: $options.disclaimer
      }, null, 10, _hoisted_4),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        (_ctx.isDiamondPlus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "icon-close",
              alt: $setup.t('buttons:close')
            }, null, 8, _hoisted_5))
          : (_openBlock(), _createElementBlock("i", _hoisted_6))
      ])
    ])
  ]))
}