import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal language-toggle",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "inner" }
const _hoisted_6 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseRadioGroup = _resolveComponent("BaseRadioGroup")

  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay general-modal larger language-toggle-modal",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isDiamondPlus)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h2", {
              class: "has-color-diamond-dark-blue",
              innerHTML: $setup.t('language_toggle.en.headline')
            }, null, 8, _hoisted_2),
            _createElementVNode("h2", {
              class: "has-color-diamond-dark-blue",
              innerHTML: $setup.t('language_toggle.es.headline')
            }, null, 8, _hoisted_3)
          ], 64))
        : (_openBlock(), _createElementBlock("h2", {
            key: 1,
            class: "has-color-dark-blue",
            innerHTML: $setup.t('language_toggle.headline')
          }, null, 8, _hoisted_4)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.changeLanguage && $options.changeLanguage(...args)), ["prevent"]))
        }, [
          _createVNode(_component_BaseRadioGroup, {
            modelValue: $options.language,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.language) = $event)),
            label: "",
            name: "language",
            options: $data.options
          }, null, 8, ["modelValue", "options"]),
          _createElementVNode("button", {
            tabindex: "0",
            class: "with-right-arrow",
            type: "submit",
            "data-e2e": "confirm_language_button",
            disabled: !$options.languageChanged
          }, _toDisplayString($setup.t('language_toggle.button')), 9, _hoisted_6)
        ], 32)
      ])
    ])
  ], 512))
}