
    import { mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import BaseTooltip from './BaseTooltip.vue';

    export default {
        components: {
            BaseTooltip,
        },

        setup () {
            const { t } = useI18next(['product_scan', 'links']);
            return { t };
        },

        computed: {
            ...mapState({
                app: 'app',
            }),
            termsLink () {
                const termsLink = `${this.app.siteURL}?locale=${this.app.locale}/#/rewardsTerms`;
                return termsLink;
            },
        },
    };
