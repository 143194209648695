import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target", "data-e2e"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    href: $options.href,
    target: $props.target,
    "data-e2e": `${$props.affiliateId}-affiliate-link`,
    onClick: _cache[0] || (_cache[0] = (...args) => ($options.trackAndGo && $options.trackAndGo(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString($setup.t(`buttons:outgoing_affiliates.${$props.affiliateId}`)), 1)
    ])
  ], 8, _hoisted_1))
}