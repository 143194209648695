
import axios from 'axios';
import api from './api';

if (typeof window !== 'undefined') {
    window.logClientError = function logClientError (e) {
        axios.post(`${api.base}/client-error`, {
            // https://developer.mozilla.org/en-US/docs/Web/API/ErrorEvent
            stack: e.error && (e.error.stack || e.error.message) || e.message || 'unknown error',
        });
    };
}
