
    import OutgoingAffiliate from '@components/OutgoingAffiliate.vue';
    import BasePage from './BasePage';
    export default {
        name: 'RedirectPage',

        components: {
            OutgoingAffiliate,
        },

        extends: BasePage,

        data () {
            return {
                pageName: 'redirect',
            };
        },

        mounted () {
            setTimeout(() => {
                top.location.href = '/redirect_to_affiliate?affiliate_id=redirect_rewards';
            }, 3000);
        },

        i18nOptions: { namespaces: ['redirect'] },
    };
