import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")

  return (_openBlock(), _createBlock(_component_BaseTooltip, { class: "tooltip-lenscrafters" }, {
    content: _withCtx(() => [
      _createElementVNode("h4", {
        innerHTML: $setup.t('lenscrafters_tooltip.headline_copy')
      }, null, 8, _hoisted_1),
      _createElementVNode("section", {
        class: "lenscrafters-data",
        innerHTML: $setup.t('lenscrafters_tooltip.body_copy')
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }))
}