// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const ExpressRewardsPatientInfoPage = async () => import(
        /* webpackChunkName: 'checkout-shop-info' */
        '../views/ExpressRewardsPatientInfoPage.vue'
    ),
    ExpressRewardsNotNeededPage = async () => import(
        /* webpackChunkName: 'checkout-shop-info' */
        '../views/ExpressRewardsNotNeededPage.vue'
    );

export default [
    { path: '/express_rewards_not_needed', component: ExpressRewardsNotNeededPage, meta: { public: true } },
    { path: '/express_rewards/:submissionId', component: ExpressRewardsPatientInfoPage, props: true, meta: { public: true } },
];
