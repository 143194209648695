import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tooltip-body" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")

  return (_openBlock(), _createBlock(_component_BaseTooltip, { class: "tooltip-not-listed" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString($setup.t('tooltip.not_listed.body_copy')) + " ", 1),
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.updateCannotFind && $options.updateCannotFind(...args)), ["prevent"]))
          }, _toDisplayString($setup.t('tooltip.not_listed.links.click_here')), 1)
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("a", {
            href: "#",
            onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openNonParticipationModal && $options.openNonParticipationModal(...args)), ["prevent"]))
          }, _toDisplayString($setup.t('tooltip.not_listed.links.participation_link')), 1)
        ])
      ]),
      _createElementVNode("div", {
        class: "tooltip-body",
        innerHTML: $options.disclaimer
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }))
}