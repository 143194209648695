import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@public/img/icon-preferred-practice.svg'


const _hoisted_1 = {
  key: 0,
  src: "/public/img/question-circle.png",
  class: "icon-question-circle",
  width: "12",
  height: "12"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")

  return (_openBlock(), _createBlock(_component_BaseTooltip, { class: "preferred-tooltip-container" }, {
    tooltip: _withCtx(() => [
      ($props.icon)
        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _imports_0,
            class: "preferred-tooltip-icon icon",
            alt: $setup.t('reward_results.preferred_image')
          }, null, 8, _hoisted_2))
    ]),
    content: _withCtx(() => [
      _createElementVNode("span", {
        class: "tooltip-body",
        innerHTML: $setup.t('preferred_tooltip', { site_url: _ctx.app.siteURL, terms_link: $options.termsLink })
      }, null, 8, _hoisted_3)
    ]),
    _: 1
  }))
}