const states = {
    AL: { name: 'Alabama', abbrev: 'AL' },
    AK: { name: 'Alaska', abbrev: 'AK' },
    AZ: { name: 'Arizona', abbrev: 'AZ' },
    AR: { name: 'Arkansas', abbrev: 'AR' },
    CA: { name: 'California', abbrev: 'CA' },
    CO: { name: 'Colorado', abbrev: 'CO' },
    CT: { name: 'Connecticut', abbrev: 'CT' },
    DE: { name: 'Delaware', abbrev: 'DE' },
    DC: { name: 'District of Columbia', abbrev: 'DC' },
    FL: { name: 'Florida', abbrev: 'FL' },
    GA: { name: 'Georgia', abbrev: 'GA' },
    HI: { name: 'Hawaii', abbrev: 'HI' },
    ID: { name: 'Idaho', abbrev: 'ID' },
    IL: { name: 'Illinois', abbrev: 'IL' },
    IN: { name: 'Indiana', abbrev: 'IN' },
    IA: { name: 'Iowa', abbrev: 'IA' },
    KS: { name: 'Kansas', abbrev: 'KS' },
    KY: { name: 'Kentucky', abbrev: 'KY' },
    LA: { name: 'Louisiana', abbrev: 'LA' },
    ME: { name: 'Maine', abbrev: 'ME' },
    MD: { name: 'Maryland', abbrev: 'MD' },
    MA: { name: 'Massachusetts', abbrev: 'MA' },
    MI: { name: 'Michigan', abbrev: 'MI' },
    MN: { name: 'Minnesota', abbrev: 'MN' },
    MS: { name: 'Mississippi', abbrev: 'MS' },
    MO: { name: 'Missouri', abbrev: 'MO' },
    MT: { name: 'Montana', abbrev: 'MT' },
    NE: { name: 'Nebraska', abbrev: 'NE' },
    NV: { name: 'Nevada', abbrev: 'NV' },
    NH: { name: 'New Hampshire', abbrev: 'NH' },
    NJ: { name: 'New Jersey', abbrev: 'NJ' },
    NM: { name: 'New Mexico', abbrev: 'NM' },
    NY: { name: 'New York', abbrev: 'NY' },
    NC: { name: 'North Carolina', abbrev: 'NC' },
    ND: { name: 'North Dakota', abbrev: 'ND' },
    OH: { name: 'Ohio', abbrev: 'OH' },
    OK: { name: 'Oklahoma', abbrev: 'OK' },
    OR: { name: 'Oregon', abbrev: 'OR' },
    PA: { name: 'Pennsylvania', abbrev: 'PA' },
    RI: { name: 'Rhode Island', abbrev: 'RI' },
    SC: { name: 'South Carolina', abbrev: 'SC' },
    SD: { name: 'South Dakota', abbrev: 'SD' },
    TN: { name: 'Tennessee', abbrev: 'TN' },
    TX: { name: 'Texas', abbrev: 'TX' },
    UT: { name: 'Utah', abbrev: 'UT' },
    VT: { name: 'Vermont', abbrev: 'VT' },
    VA: { name: 'Virginia', abbrev: 'VA' },
    WA: { name: 'Washington', abbrev: 'WA' },
    WV: { name: 'West Virginia', abbrev: 'WV' },
    WI: { name: 'Wisconsin', abbrev: 'WI' },
    WY: { name: 'Wyoming', abbrev: 'WY' },
};

const stateArray = (values = states) => Object.keys(values)
.filter(value => !(/^(\.|CA-)/).test(value))
.map(stateKey => ({ value: values[stateKey].abbrev, label: values[stateKey].abbrev }))
.sort((a, b) => {
    const aVal = a.value.toUpperCase();
    const bVal = b.value.toUpperCase();

    return (aVal < bVal) ? -1 : 1;
});

module.exports = {
    stateArray,
};
