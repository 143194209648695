
    import { createPopper } from '@popperjs/core';
    export default {
        data () {
            return {
                id: undefined,
                show: false,
                timeout: undefined,
                popperInstance: undefined,
                aboveTop: 0,
            };
        },

        computed: {
            cssStyle () {
                return {
                    '--bottom-offset': `${this.aboveTop}px`,
                };
            },
        },

        mounted () {
            this.$nextTick(() => {
                this.popperInstance = createPopper(this.$refs.tooltip, this.$refs.tooltipContainer, {
                    placement: 'top',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                padding: 8,
                            },
                        },
                        {
                            name: 'preventOverflow',
                        },
                    ],
                });
            });
            window.addEventListener('scroll', this.manageTopPosition);
        },

        beforeUnmount () {
            window.removeEventListener('scroll', this.manageTopPosition);
        },

        methods: {
            showTooltip () {
                this.popperInstance.update();
                this.clearTimeout();
                this.show = true;
                this.manageTopPosition();
            },
            hideTooltip () {
                this.timeout = setTimeout(() => {
                    this.show = false;
                }, 500);
            },
            clearTimeout () {
                clearTimeout(this.timeout);
            },
            manageTopPosition () {
                this.aboveTop = this.$refs.tooltipContainer.getBoundingClientRect().top;
            },
        },
    };
