
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'PreviewPhotoModal',

        extends: BaseModal,

        props: {
            photo: {
                type: String,
                required: true,
            },
        },

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },

        data () {
            return {
                modalName: 'preview-photo-modal',
            };
        },
    };
