import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = ["alt"]
const _hoisted_3 = {
  key: 1,
  class: "fal fa-times"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay edit-patient-confirm-modal",
    onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString($setup.t('edit_patient_complete.headline_copy', { first_name: _ctx.profile.first_name })), 1),
      _createElementVNode("p", null, _toDisplayString($setup.t('edit_patient_complete.body_copy')), 1),
      _createElementVNode("button", {
        class: "with-right-arrow",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _toDisplayString($setup.t('links:continue')), 1),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        (_ctx.isDiamondPlus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "icon-close",
              alt: $setup.t('buttons:close')
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("i", _hoisted_3))
      ])
    ])
  ]))
}