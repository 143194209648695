
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'GeneralMessageModal',

        extends: BaseModal,

        props: {
            headline: {
                type: String,
                required: false,
                default: undefined,
            },
            body: {
                type: String,
                required: true,
            },
            cta: {
                type: String,
                required: false,
                default: undefined,
            },
            link: {
                type: String,
                required: false,
                default: undefined,
            },
        },

        setup () {
            const { t } = useI18next(['buttons']);
            return { t };
        },

        data () {
            return {
                modalName: 'general_message',
            };
        },
    };
