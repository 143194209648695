
    import { useI18next } from '@composables/i18next';
    import { useBasePage } from '@composables/page';

    export default {
        name: 'ErrorPage',

        setup () {
            const { t } = useI18next(['error']);
            useBasePage('error');
            return { t };
        },

        data () {
            return {
                pageName: 'error',
            };
        },
    };
