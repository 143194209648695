
    import ImageIcon from './ImageIcon.vue';

    export default {

        name: 'PhoneIcon',

        components: { ImageIcon },

        props: {
            circleColorClass: {
                type: String,
                default: 'magenta',
            },
            strokeColor: {
                type: String,
                default: '#ffffff',
            },
        },
    };
