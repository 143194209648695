
import axios from 'axios';
import config from 'rocketship-config';
import api from '../../api';

const defaultState = {
    loading: false,
    cannot_find: false,
    cannot_find_form: false,
    limitedForActivePatients: false,
    currentPatientInfo: {
        sap: undefined,
        profile_id: undefined,
        name: undefined,
        relation_to_profile: undefined,
        address1: '',
        address2: '',
        city: '',
        state: undefined,
        postal_code: '',
        country: '',
    },
    resubmission: false,
    eye_care_professional_postal_code: undefined,
    eye_care_professional_account_name: undefined,
    selectedECP: {},
    eye_care_professional_list: undefined,
    purchase_submission_id: undefined,
};

const preferredPracticePrograms = ["preferredpractice","pilot","trial","2020_q1","2020_q2","2020_q3","2020_q4","2021_q1","2021_q2","2021_q3","2021_q4","2022_q1","2022_q2","2022_q3","2022_q4","2023_q1","2023_q2","2023_q3","2023_q4","2024_q1","2024_q2","2024_q3","2024_q4"];

const ecpListEndpoint = 'eye-care-professionals/list/';
const patientEndpoint = 'patients/';

const state = {
    ...defaultState,
    patients: [],
};
const getters = {
    getEyeCareProfessional (state) {
        return {
            eye_care_professional_postal_code: state.eye_care_professional_postal_code,
            eye_care_professional_account_name: state.eye_care_professional_account_name,
        };
    },
    getEyeCareProfessionalList (state) {
        return state.eye_care_professional_list;
    },
    getCurrentPatientInfo (state) {
        return state.currentPatientInfo;
    },
    getSelectedECP (state) {
        const { selectedECP: { account_name } } = state;
        return (!account_name) ? false : state.selectedECP;
    },
    getPatients (state) {
        return state.patients;
    },
    isSelfEntered (state) {
        const self = state.patients.filter((patient) => patient.relation_to_profile === 'SELF');
        return self.length > 0;
    },
    hasPreferredPracticeInList ({ eye_care_professional_list = [] }, getters, rootState) {
        const element = eye_care_professional_list.find(element => {
            if (!element.programs) return false;
            const keys = Object.keys(element.programs);
            const activeBonusPrograms = keys.filter(key => {
                if (preferredPracticePrograms.includes(key.toLowerCase())) {

                    const program = element.programs[key];
                    const startDate = new Date(program.start_date).getTime();
                    const endDate = new Date(program.end_date).getTime();
                    const currentTime = rootState.app.now;

                    return startDate <= currentTime && currentTime <= endDate;
                }
                return false;

            });
            return activeBonusPrograms.length > 0;
        });

        return element !== undefined;
    },
    inMilestoneProgram (state, getters, rootState) {
        return (milestone) => {
            if (!state.selectedECP.programs || !state.selectedECP.programs[milestone]) return false;

            // test for if it is in date
            const program = state.selectedECP.programs[milestone];
            const startDate = new Date(program.start_date.replaceAll('-', '/')).getTime();
            const endDate = new Date(program.end_date.replaceAll('-', '/')).getTime();
            const currentTime = rootState.app.now;
            return startDate <= currentTime && currentTime <= endDate;
        };
    },
};

const mutations = {
    updateInfo (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];
            }
        }
    },
    updateEyeCareProfessionalList (state, data) {
        state.eye_care_professional_list = data;
    },
    setLoading (state, loading) {
        state.loading = loading;
    },
};

const actions = {
    resetForm ({ commit }) {
        commit('updateInfo', defaultState);
    },
    resetCurrentPatient ({ commit }) {
        commit('updateInfo', { currentPatientInfo: defaultState.currentPatientInfo });
    },
    resetEyCareProfessional ({ commit }) {
        commit('updateInfo', {
            cannot_find: false,
            cannot_find_form: false,
            eye_care_professional_postal_code: undefined,
            eye_care_professional_account_name: undefined,
        });
    },
    submitPatientInfo ({ commit, state }, data) {
        const currentPatientInfo = {
            ...state.currentPatientInfo,
            ...data,
        };
        commit('updateInfo', { currentPatientInfo });
    },
    updateSelectedECP ({ commit, dispatch }, data) {
        if (data) {
            const { sap } = data;
            dispatch('submitPatientInfo', { sap });
        }
        commit('updateInfo', { selectedECP: data });
    },
    setCurrentPatientInfo ({ commit }, data) {
        commit('updateInfo', { currentPatientInfo: data });
    },
    async submitEyeCareProfessionalSearch ({ commit, dispatch }, data) {
        commit('updateInfo', { ...data.params });
        try {
            const response = await dispatch('makeCall', {
                endpoint: `${ecpListEndpoint}by-postal-code-and-account-name`,
                data,
                type: 'get',
            });

            commit('updateEyeCareProfessionalList', response.data.result.eyeCareProfessionalList);
        }
        catch (error) {
            console.error('error', error);
        }
    },
    async submitSearchByRadius ({ commit, dispatch }, data) {
        try {
            const response = await dispatch('makeCall', {
                endpoint: `${ecpListEndpoint}by-radius`,
                data,
                type: 'get',
            });
            commit('updateEyeCareProfessionalList', response.data.result.eyeCareProfessionalList);
        }
        catch (error) {
            console.error('error', error);
        }
    },
    async submitPatient ({ dispatch, state, commit }, data) {
        const createOrUpdate = data.update;

        try {
            const response = await dispatch('makeCall', {
                endpoint: `${patientEndpoint}${createOrUpdate}`,
                data: {
                    ...state.currentPatientInfo,
                    ...data,
                    sap: state.selectedECP.sap,
                },
            });

            const { data: { result: { patient } } } = response;
            dispatch('submitPatientInfo', patient);

            return response;
        }
        catch (error) {
            console.error('error', error);
            throw error;
        }
    },
    async getPatients ({ dispatch, commit }) {
        try {
            const response = await dispatch('makeCall', {
                type: 'get',
                endpoint: 'patients/get-patients/me',
            });
            const { data: { result: { patientList } } } = response;
            commit('updateInfo', { patients: patientList });
            dispatch('limitedForActivePatients');
            return patientList;
        }
        catch (error) {
            console.error('error', error);
        }
    },
    async limitedForActivePatients ({ dispatch, commit }) {
        try {
            const response = await dispatch('makeCall', {
                type: 'get',
                endpoint: `${patientEndpoint}active/limited`,
            });

            const { data: { limited } } = response;
            commit('updateInfo', { limitedForActivePatients: limited });
        }
        catch (error) {
            console.error('error', error);
            throw error;
        }
    },
    async getEyeCareProfessionalBySap ({ dispatch, commit }, sap) {
        try {
            const response = await dispatch('makeCall', {
                endpoint: `eye-care-professionals/get-by-sap/${sap}`,
                type: 'get',
            });
            if (response.data?.eyeCareProfessional?.status !== 'active') {
                return false;
            }
            commit('updateInfo', { selectedECP: response.data.eyeCareProfessional });
        }
        catch (error) {
            console.error('error', error);
            throw error;
        }
    },
    async isValidEyeCareProfessional ({ dispatch }, sap) {
        if (!sap) {
            return false;
        }
        else if (sap.toString() === '999') {
            return true;
        }
        try {
            const response = await dispatch('makeCall', {
                endpoint: `eye-care-professionals/get-by-sap/${sap}`,
                type: 'get',
            });
            return true;
        }
        catch (error) {
            /**
             * TODO: Handle returned error message values, if exists
             * Error Object node: error.response.data.error
             * Possible values:
             *  Invalid eye care professional
             *      Reason: ECP was not found
             *  Inactive eye care professional
             *      Reason: ECP status does not equal active
             * Flag Practice Zip Code & Practice Name fields as required
             * Do not let user proceed
             */
            return false;
        }
    },
    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data = {},
    }) {
        commit('setLoading', true);
        try {
            const response = await axios[type](`${api.base}/${endpoint}`, data);
            commit('setLoading', false);

            return response;
        }
        catch (err) {
            commit('setLoading', false);
            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );
            throw err;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
