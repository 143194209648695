
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'OamoeOverrideModal',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['modal_content', 'buttons']);
            return { t };
        },

        data () {
            return {
                modalName: 'oamoe_override',
            };
        },

        methods: {
            navigateToLogIncomplete () {
                window.location = `${window.location.origin}/admin#/patient-tools`;
            },
            continueSubmission () {
                this.closeModal('ecp_exception');
            },
        },
    };
