import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-overlay impersonation-comment-modal" }
const _hoisted_2 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "field" }
const _hoisted_6 = { for: "additionalComments" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Validator = _resolveComponent("Validator")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        innerHTML: $setup.t('comment_admin_modal.headline_copy')
      }, null, 8, _hoisted_3),
      _createElementVNode("p", {
        innerHTML: $setup.t('comment_admin_modal.body_copy')
      }, null, 8, _hoisted_4),
      _createVNode(_component_Validator, {
        ref: "validator",
        guards: $data.validatorGuards,
        data: { additionalComments: $data.additionalComments }
      }, {
        default: _withCtx(({
                    hasErrors, isError,
                }) => [
          _createElementVNode("form", {
            class: "impersonation-comment-form",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.postComment && $options.postComment(...args)), ["prevent"]))
          }, [
            _withDirectives(_createElementVNode("p", {
              class: "error error-message generic",
              "data-e2e": "faq-form-error"
            }, _toDisplayString($setup.t('comment_admin_modal.error_message')), 513), [
              [_vShow, hasErrors]
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass([{ error: isError('additionalComments') }, "field fullwidth"])
              }, [
                _createElementVNode("label", _hoisted_6, _toDisplayString($setup.t('comment_admin_modal.fields.additionalComments')), 1),
                _withDirectives(_createElementVNode("textarea", {
                  id: "additionalComments",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.additionalComments) = $event)),
                  class: "form-field",
                  name: "additionalComments"
                }, null, 512), [
                  [_vModelText, $data.additionalComments]
                ])
              ], 2),
              _createElementVNode("button", {
                class: "button with-right-arrow",
                type: "submit",
                innerHTML: $setup.t('comment_admin_modal.fields.submit')
              }, null, 8, _hoisted_7)
            ])
          ], 32)
        ]),
        _: 1
      }, 8, ["guards", "data"])
    ])
  ]))
}