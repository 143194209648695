const dateBrandClauses = {"_public":true,"clauses":[{"brands":["TRP","AUP"],"min":"2022/7/1"},{"brands":["FAL"],"min":"2021/1/1"},{"brands":["1OH","TAS","1DM","1MA","1MM","1MM"],"min":"2020/6/30","months":[3,6,9]},{"brands":["OWT"],"min":"2020/3/31","months":[3,9]}]}.clauses;
// adding a log so we can see what config file different people are getting
const purchaseDateCompatibility = ({ purchaseDate, selectedBrands, monthSupplies }) => {
    const dateOfPurchase = new Date(purchaseDate.replaceAll('-0', '-'));
    for (const { brands, months, min, max } of dateBrandClauses) {
        let tooEarly = false;
        let tooLate = false;
        if (selectedBrands.filter(item => brands.includes(item)).length === 0) {
            continue;
        }
        if (months && monthSupplies.filter(item => months.includes(item)).length === 0) {
            continue;
        }
        if (min) {
            const minDate = new Date(min.replaceAll('-0', '-'));
            tooEarly = dateOfPurchase.getTime() <= minDate.getTime();
        }
        if (max) {
            const maxDate = new Date(max.replaceAll('-0', '-'));
            tooLate = dateOfPurchase.getTime() >= maxDate.getTime();
        }
        if (tooEarly || tooLate) {
            return false;
        }
    }
    return true;
};

const calculateMonthSupply = ({ brand, boxCount, lensCount }) => {
    let months = 0;
    switch (brand) {
        case 'ATL':
        case 'AAS':
        {
            months = lensCount * boxCount;
            break;
        }
        case 'FAL':
        case 'CYP':
        case 'PH':
        case 'OWT':
        {
            months = Math.round((lensCount * 14 * boxCount) / 30);
            break;
        }
        case '1MM':
        case '1MA':
        case 'TAS':
        case '1OH':
        case '1DM':
        {
            months = (lensCount * boxCount) / 30;
            break;
        }
    }
    return months;
};

module.exports = {
    purchaseDateCompatibility,
    calculateMonthSupply,
};

