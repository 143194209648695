
    import { mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'VisaModal',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['faq_modals']);
            return { t };
        },

        data () {
            return {
                modalName: 'visa',
            };
        },
        computed: {
            ...mapState(['app']),
            disclaimer () {
                const { subPhase } = this.app;
                const copy = this.t('faq_modals:visa.footer_copy');
                return copy[subPhase] || copy.default;
            },
        },
    };
