import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal faq-modal",
  role: "dialog"
}
const _hoisted_2 = {
  key: 0,
  class: "inner"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "inner" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "inner" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["alt"]
const _hoisted_12 = {
  key: 1,
  class: "fal fa-times"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "el",
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isDiamondPlus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h2", {
              innerHTML: $setup.t('2steps.headline_copy')
            }, null, 8, _hoisted_3),
            _createElementVNode("div", {
              innerHTML: $setup.t('2steps.dp_body_copy', { siteURL: _ctx.app.siteURL })
            }, null, 8, _hoisted_4),
            _createElementVNode("button", {
              class: "with-right-arrow",
              onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"])),
              innerHTML: $setup.t('2steps.btn_copy')
            }, null, 8, _hoisted_5)
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", {
                innerHTML: $setup.t('2steps.headline_copy')
              }, null, 8, _hoisted_7),
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal({ formFocus: true })), ["prevent"])),
                innerHTML: $setup.t('2steps.body_copy', { siteURL: _ctx.app.siteURL })
              }, null, 8, _hoisted_8)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"])),
                innerHTML: $setup.t('2steps.btn_copy')
              }, null, 8, _hoisted_10)
            ])
          ], 64)),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        (_ctx.isDiamondPlus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "icon-close",
              alt: $setup.t('buttons:close')
            }, null, 8, _hoisted_11))
          : (_openBlock(), _createElementBlock("i", _hoisted_12))
      ])
    ])
  ], 512))
}