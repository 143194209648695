
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'VideoModal',

        extends: BaseModal,

        props: {
            pageName: {
                type: String,
                default: '',
            },
            reward: {
                type: Object,
                default () {
                    return {};
                },
            },
        },

        setup () {
            const { t } = useI18next(['buttons']);
            return { t };
        },

        data () {
            return {
                modalName: 'video-modal',
            };
        },
    };
