
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: undefined,
            },
            isError: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            selectOne: {
                type: String,
                default: 'Select One',
            },
        },
        emits: ['input'],
    };
