
    import { useI18next } from '@composables/i18next';
    import { mapState } from 'vuex';
    import openModal, { LanguageToggleModal } from '../modals';

    export default {
        name: 'LanguageToggle',

        setup () {
            const { t } = useI18next(['links']);
            return { t };
        },

        computed: {
            ...mapState(['app']),
            isSpanish () {
                return this.app.userLanguage === 'es';
            },
            isEnglish () {
                return this.app.userLanguage === 'en';
            },
        },

        methods: {
            openModal,
            openLanguageToggle () {
                this.openModal(LanguageToggleModal);
            },
        },

    };
