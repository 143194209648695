import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 330 330"
}
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]
const _hoisted_5 = ["stroke"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ImageIcon = _resolveComponent("ImageIcon")

  return (_openBlock(), _createBlock(_component_ImageIcon, {
    "circle-color-class": $props.circleColorClass,
    class: "icon phone-icon"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock("svg", _hoisted_1, [
        _createElementVNode("g", null, [
          _createElementVNode("path", {
            d: "M1730.83,520.67l-7.72-7.72a39.36,39.36,0,0,0-55.69,0,24.38,24.38,0,0,1-34.42,0l-66-66a24.35,24.35,0,0,1,0-34.41,39.41,39.41,0,0,0,0-55.69l-7.72-7.72a39.42,39.42,0,0,0-55.68,0l-.29.28a80,80,0,0,0,0,113l114.2,114.2a80,80,0,0,0,113,0l.29-.29A39.43,39.43,0,0,0,1730.83,520.67Z",
            transform: "translate(-1475 -275)",
            fill: "none",
            stroke: $props.strokeColor,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "10"
          }, null, 8, _hoisted_2),
          _createElementVNode("path", {
            d: "M1570.73,295.72a160.07,160.07,0,0,1,213.55,213.55",
            transform: "translate(-1475 -275)",
            fill: "none",
            stroke: $props.strokeColor,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "10"
          }, null, 8, _hoisted_3),
          _createElementVNode("path", {
            d: "M1602.18,327.12a119.11,119.11,0,0,1,150.7,150.7",
            transform: "translate(-1475 -275)",
            fill: "none",
            stroke: $props.strokeColor,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "10"
          }, null, 8, _hoisted_4),
          _createElementVNode("path", {
            d: "M1635.25,360.19c1.57-.09,3.16-.14,4.75-.14A80,80,0,0,1,1720,440c0,1.59,0,3.17-.14,4.74",
            transform: "translate(-1475 -275)",
            fill: "none",
            stroke: $props.strokeColor,
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "10"
          }, null, 8, _hoisted_5)
        ])
      ]))
    ]),
    _: 1
  }, 8, ["circle-color-class"]))
}