
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    EscalatePage = async () => import(
        /* webpackChunkName: 'escalate' */
        '../views/EscalatePage.vue'
    ),
    EscalationThanksPage = async () => import(
        /* webpackChunkName: 'escalation-thanks' */
        '../views/EscalationThanksPage.vue'
    );

export default [
    { path: '/escalate', component: EscalatePage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
    { path: '/escalation_thanks', component: EscalationThanksPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
];
