
    import { mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import BaseTooltip from './BaseTooltip.vue';

    export default {
        components: {
            BaseTooltip,
        },

        props: {
            icon: {
                type: String,
                required: false,
                default: undefined,
            },
        },

        setup () {
            const { t } = useI18next(['reward_estimator']);
            return { t };
        },

        computed: {
            ...mapState(['app']),
            termsLink () {
                const termsLink = `${this.app.siteURL}?locale=${this.app.locale}/#/preferredRewardsTerms`;
                return termsLink;
            },
        },
    };
