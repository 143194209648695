import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")

  return (_openBlock(), _createBlock(_component_BaseTooltip, { class: "scandit-tooltip" }, {
    content: _withCtx(() => [
      _createElementVNode("span", {
        innerHTML: $setup.t('product_scan:headline_tooltip', { terms_link: $options.termsLink })
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}