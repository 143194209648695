
import Cookies from 'js-cookie';
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    isAutomatedTest = !!process.env.RS_TEST,
    isProd = document.location.href.match(/prod|promo.acuvue/) || isAutomatedTest,
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    IneligibleAgePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleAgePage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LimiterPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    OnboardingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/OnboardingPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        const onboardingCookie = Cookies.get(`${store.state.app.name}_hide_onboarding`);
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }

        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            if (!onboardingCookie) {
                return '/onboarding';
            }
            else {
                return '/dashboard';
            }
        }
        if (document.location.href.match(/localhost:808/)) {
            return '/login';
        }

        // Default route for a user we know nothing about.
        return '/redirect-page';
    } },
    {
        path: '/login',
        component: LoginPage,
        beforeEnter: (to, from, next) => {
            if (isProd) {
                next('/redirect-page');
            }
            else { next(); }
        },
        meta: { public: true },
    },
    { path: '/limiter/:type', component: LimiterPage, props: true },
    { path: '/limiter', component: LimiterPage },
    { path: '/ineligible_age', component: IneligibleAgePage, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/onboarding', component: OnboardingPage },
];
