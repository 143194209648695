
    export default {
        props: {
            isError: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
                default: '',
            },
            isAutomatedTest: {
                type: Boolean,
                required: true,
                default: false,
            },
        },

        data () {
            const isProd = false;
            const recaptchaPubKey = isProd ? "6Le-PjAmAAAAAGiONDvhBxz3OqJIrqIntaj7080H" : "6LenuS8mAAAAAKd97QHFGo08_y2bbKkNa1fBjm5i";

            return {
                recaptchaPubKey,
            };
        },

        mounted () {
            window.recaptchaComplete = this.resizeCaptcha;
            window.recaptchaExpired = this.recaptchaExpired;

            if (!this.isAutomatedTest) {
                this.$nextTick(() => {
                    const recaptchaInterval = window.setInterval(() => {
                        if (
                            window.grecaptcha &&
                            grecaptcha.render
                        ) {
                            if (this.$refs.gRecaptcha && this.$refs.gRecaptcha.matches(':empty')) {
                                grecaptcha.render(this.$refs.gRecaptcha, { sitekey: this.recaptchaPubKey, 'expired-callback': window.recaptchaExpired });
                                this.resizeCaptcha();
                                window.addEventListener('resize', this.resizeCaptcha);
                                clearInterval(recaptchaInterval);
                            }
                        }
                    }, 500);
                });
            }
        },

        beforeUnmount () {
            window.removeEventListener('resize', this.resizeCaptcha);
        },

        methods: {
            resizeCaptcha () {
                const recaptchaContainerEl = document.querySelector('.g-recaptcha');
                // Get an accurate width from .g-recaptcha and getBoundingClientRect()
                const sizeEl = recaptchaContainerEl.children[0];
                const { width } = recaptchaContainerEl.getBoundingClientRect();
                sizeEl.style.transform = sizeEl.style.webkitTransform = 'scale(' + (width / 304) +  ')';
                sizeEl.style.transformOrigin = '0 0';
                sizeEl.style.width = width + 'px';
                sizeEl.style.height = width * 78 / 304 + 'px'; // Default recaptcha is 304 x 78, convert using aspect ratio
            },

            // Needs a slight delay to actually resize it to the correct size.
            recaptchaExpired () {
                setTimeout(window.resizeCaptcha, 250);
            },

            reset () {
                if (window.grecaptcha) {
                    grecaptcha.reset();
                }
            },
        },
    };
