
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    PatientEntryPage = async () => import(
        /* webpackChunkName: 'patient' */
        '../views/PatientEntryPage.vue'
    ),
    PatientSelectPage = async () => import(
        /* webpackChunkName: 'patient' */
        '../views/PatientSelectPage.vue'
    ),
    DashboardPage = async () => import(
        /* webpackChunkName: 'patient' */
        '../views/DashboardPage.vue'
    );

export default [
    {
        path: '/patient_select/:edit',
        name: 'patient_select_edit',
        component: PatientSelectPage,
        props: route => ({ edit: route.params.edit }), // Dynamically pass 'edit' prop
    },
    {
        path: '/patient_select',
        name: 'patient_select',
        component: PatientSelectPage,
    },
    {
        path: '/patient_entry/:edit',
        name: 'patient_entry_edit',
        component: PatientEntryPage,
        props: route => ({ edit: route.params.edit }),  // Dynamically pass 'edit' prop
        meta: { prevPath: ['/patient_select'] },
    },
    {
        path: '/patient_entry/',
        name: 'patient_entry',
        component: PatientEntryPage,
        meta: { prevPath: ['/patient_select'] },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardPage,
    },
];
