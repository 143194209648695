
    import { mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'NonParticipatingModal',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },

        data () {
            return {
                modalName: 'non_participating',
            };
        },

        computed: {
            ...mapState(['app']),
            disclaimer () {
                const { subPhase } = this.app;
                const copy = this.t('modal_content:non_participating.disclaimer');
                return copy[subPhase] || copy.default;
            },
        },

    };
