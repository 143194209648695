import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  ref: "el",
  class: "modal-overlay"
}
const _hoisted_2 = {
  class: "modal ecp-search-select",
  role: "dialog"
}
const _hoisted_3 = { class: "top-container" }
const _hoisted_4 = { class: "ecp-filter-form" }
const _hoisted_5 = { class: "field base-search ecp-filter-form-field" }
const _hoisted_6 = {
  class: "ecp-filter-label",
  for: "filter_form"
}
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "callout ecp-search-results-container" }
const _hoisted_9 = {
  key: 0,
  class: "fa fa-spinner fa-pulse fa-4x fa-fw"
}
const _hoisted_10 = { class: "ecp-search-results" }
const _hoisted_11 = { class: "scroll-container" }
const _hoisted_12 = { class: "ecp-details" }
const _hoisted_13 = { class: "ecp-info" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "ecp-detail-address" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "select-container ecp-select-button-container" }
const _hoisted_21 = {
  key: 0,
  class: "selected with-right-arrow tertiary"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 1,
  class: "ecp-disclaimer ecp-doctor-need-help"
}
const _hoisted_25 = { class: "disclaimer" }
const _hoisted_26 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")
  const _component_PreferredTooltip = _resolveComponent("PreferredTooltip")
  const _component_LenscraftersTooltip = _resolveComponent("LenscraftersTooltip")
  const _component_UnaffiliatedEcpTooltip = _resolveComponent("UnaffiliatedEcpTooltip")
  const _component_NotListedTooltip = _resolveComponent("NotListedTooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseSelect, {
          modelValue: $data.postal_code_radius,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.postal_code_radius) = $event)),
          name: "postal_code_radius",
          class: "ecp-search-radius",
          label: $setup.t('radius_form.label', { postal_code: _ctx.ecpForm.eye_care_professional_postal_code }),
          "select-one": $setup.t('select_one'),
          options: $options.radiusOptions
        }, null, 8, ["modelValue", "label", "select-one", "options"]),
        _createElementVNode("form", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString($setup.t('filter_form.label')), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.filter) = $event)),
              class: "ecp-filter-text",
              type: "text",
              placeholder: $setup.t('filter_form.infield_label')
            }, null, 8, _hoisted_7), [
              [_vModelText, $data.filter]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.$store.state.patient.loading)
          ? (_openBlock(), _createElementBlock("i", _hoisted_9))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h4", _hoisted_10, [
                ($options.filteredList.length == 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString($setup.t('list_headline', { count: $options.filteredList.length || 0 })), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString($setup.t('list_headline_plural', { count: $options.filteredList.length || 0 })), 1)
                    ], 64))
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredList, (ecp, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: `sap_${index}`,
                      class: "ecp-item"
                    }, [
                      _createElementVNode("div", _hoisted_12, [
                        (_ctx.hasPreferredPracticeInList && ecp.programs && $options.preferredActive(ecp.programs))
                          ? (_openBlock(), _createBlock(_component_PreferredTooltip, {
                              key: 0,
                              class: "ecp-preferred-container"
                            }))
                          : (ecp.isLenscrafters === true)
                            ? (_openBlock(), _createBlock(_component_LenscraftersTooltip, {
                                key: 1,
                                class: "ecp-lenscrafters-container"
                              }))
                            : (ecp?.affiliated_now === false || !ecp?.affiliated_now)
                              ? (_openBlock(), _createBlock(_component_UnaffiliatedEcpTooltip, {
                                  key: 2,
                                  class: "ecp-unaffiliated-container"
                                }))
                              : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("h4", {
                            class: "ecp-detail-name",
                            innerHTML: ecp.display_name || ecp.account_name
                          }, null, 8, _hoisted_14),
                          _createElementVNode("p", _hoisted_15, [
                            _createElementVNode("span", {
                              class: "ecp-address-1 ecp-address-text",
                              innerHTML: $options.handleCase(ecp.address1)
                            }, null, 8, _hoisted_16),
                            _createTextVNode(),
                            _createElementVNode("span", {
                              class: "ecp-city ecp-address-text",
                              innerHTML: $options.handleCase(ecp.city)
                            }, null, 8, _hoisted_17),
                            _createTextVNode(", "),
                            _createElementVNode("span", {
                              class: "ecp-state ecp-address-text",
                              innerHTML: ecp.state
                            }, null, 8, _hoisted_18),
                            _createTextVNode(),
                            _createElementVNode("span", {
                              class: "ecp-postal-code ecp-address-text",
                              innerHTML: ecp.postal_code
                            }, null, 8, _hoisted_19)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        (_ctx.patientInfo.sap === ecp.sap)
                          ? (_openBlock(), _createElementBlock("button", _hoisted_21, _toDisplayString($setup.t('selected')), 1))
                          : (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              class: "with-right-arrow tertiary",
                              "data-e2e": "button-select-ecp",
                              onClick: $event => ($options.selectECP(ecp))
                            }, _toDisplayString($setup.t('select')), 9, _hoisted_22))
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ], 64))
      ]),
      (_ctx.isAdminImpersonation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => ($options.cannotFindDoctor && $options.cannotFindDoctor(...args)), ["prevent"]))
              }, "Doctor Not Listed")
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createElementVNode("p", _hoisted_25, [
              _createTextVNode(_toDisplayString($setup.t('cannot_find_doctor')) + " ", 1),
              _createVNode(_component_NotListedTooltip, { onCloseModal: $options.cannotFindDoctor }, null, 8, ["onCloseModal"])
            ])
          ])),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "error-modal-close",
        class: "close-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_26)
      ])
    ])
  ], 512))
}