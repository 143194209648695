
import { mapState } from 'vuex';
import { useI18next } from '@composables/i18next';
import termsSubPhase from '../utilities/terms-sub-phase';

import { page } from '../plugins/WebAnalytics';
import store from '../store';

export default {
    name: 'BasePage',

    setup () {
        const { t, vT } = useI18next(['global']);
        return { t };
    },

    data () {
        return {
            pageName: '',
            pageClasses: [],
            isDiamondPlus: true,
        };
    },

    computed: {
        // Map state very commonly used by pages, for convenience.
        ...mapState([
            'app',
            'profile',
        ]),
        prepaidCard () {
            let copy = this.t('global:prepaid_card');
            // This is for the front end tests. Will need to be refactored;
            copy = (typeof copy === 'string') ? { default: {} } : copy;
            const { card_format = {} } = (copy[this.app.subPhase]) || (copy.default);
            const { first_card_mention = '', second_card_mention = '', following_card_mentions = '' } = card_format;
            return { first_card_mention, second_card_mention, following_card_mentions };
        },
    },


    beforeMount () {
        store.commit('ui/pageEnter', this);

        const { query: { affiliate_id } } = this.$route;

        // If there was an affiliate in the "query string" embedded in the Vue
        // Router hash portion of the URL, pass it along to the app store.
        if (affiliate_id) {
            store.commit('app/hashAffiliateUpdate', affiliate_id);
        }

        page();
    },

    mounted () {
        document.body.setAttribute('data-e2e-page', this.pageName);
        document.body.setAttribute('id', this.pageName);
        document.body.classList.add(this.pageName);
        if (this.checkDiamondPlus()) {
            this.isDiamondPlus = true;
            document.documentElement.classList.add('diamond-plus');
            document.body.classList.add('diamond-plus');
        }
    },

    beforeUnmount () {
        document.body.removeAttribute('data-e2e-page');
    },

    beforeRouteLeave (to, from, next) {
        store.commit('ui/pageLeave');

        next();
    },

    methods: {
        find (selector) {
            if (!this.$el) return;
            return this.$el.querySelector(selector);
        },

        scrollToTop () {
            try {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                window.pym.scrollParentTo('rewardsIframe');
            }
            catch (error) {
                console.warn('Unable to adjust scroll position.');
            }
        },

        checkDiamondPlus () {
            const milestones = termsSubPhase.getCurrentMilestones(this.app.now);
            return milestones.includes('diamond_plus');
        },
    },
};
