
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    TermsAndConditionsPage = async () => import(
        /* webpackChunkName: 'terms-and-conditions' */
        '../views/TermsAndConditionsPage.vue'
    ),
    ExpressRewardsTerms = async () => import(
        /* webpackChunkName: 'terms-and-conditions' */
        '../views/ExpressRewardsTerms.vue'
    );

export default [
    { path: '/terms-and-conditions', alias: '/rewardsterms', component: TermsAndConditionsPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
    { path: '/terms-and-conditions?doc=preferred', alias: '/preferredrewardsterms', component: TermsAndConditionsPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
    { path: '/terms-and-conditions/:version', component: TermsAndConditionsPage, props: true, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
    // Legacy link to the terms and conditions for Express Rewards
    { path: '/expressrewardsterms', component: ExpressRewardsTerms, meta: { public: true } },
];
