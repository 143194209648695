import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "language-toggle" }
const _hoisted_2 = { class: "language-links" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "last-nav-item" }
const _hoisted_5 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          href: `${_ctx.app.siteURL}?locale=en-US`,
          class: _normalizeClass({ 'disabled': $options.isEnglish }),
          onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openLanguageToggle && $options.openLanguageToggle(...args)), ["prevent"]))
        }, _toDisplayString($setup.t('links:language_toggle.english')), 11, _hoisted_3)
      ]),
      _createElementVNode("li", _hoisted_4, [
        _createElementVNode("a", {
          href: `${_ctx.app.siteURL}?locale=es-US`,
          class: _normalizeClass({ 'disabled': $options.isSpanish }),
          onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openLanguageToggle && $options.openLanguageToggle(...args)), ["prevent"]))
        }, _toDisplayString($setup.t('links:language_toggle.spanish')), 11, _hoisted_5)
      ])
    ])
  ]))
}