import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal general-modal",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        innerHTML: $setup.t('headline_copy')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", {
        innerHTML: $setup.t('body_copy')
      }, null, 8, _hoisted_3),
      _createElementVNode("button", {
        class: "btn button with-right-arrow",
        "data-e2e": "session-expiration-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _toDisplayString($setup.t('continue')), 1)
    ])
  ]))
}