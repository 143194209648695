
    import { mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { StarIcon } from '@components/images/';
    import PreferredTooltip from '@components/tooltips/PreferredTooltip.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'ApprovalConfirmationModal',

        components: {
            StarIcon,
            PreferredTooltip,
        },

        extends: BaseModal,

        props: {
            hasRemainingExpressRewardsClaims: {
                type: Boolean,
                required: true,
                default: false,
            },
            trueUpReward: {
                type: [String, Number],
                required: true,
                default: 0,
            },
            baseReward: {
                type: [String, Number],
                required: true,
                default: 0,
            },
            bonusReward: {
                type: [String, Number],
                required: true,
                default: 0,
            },
            promocodeReward: {
                type: [String, Number],
                required: true,
                default: 0,
            },
            completeRewards: {
                type: Object,
                required: false,
                default: undefined,
            },
            totalReward: {
                type: [String, Number],
                required: true,
                default: 0,
            },
            supplies: {
                type: Object,
                required: false,
                default: undefined,
            },
        },

        setup () {
            const { t } = useI18next(['approval_confirmation', 'modal_content', 'global', 'reward_estimator', 'buttons', 'product_intake']);
            return { t };
        },

        data () {
            return {
                modalName: 'approval-confirmation',
            };
        },

        computed: {
            ...mapState(['purchase', 'app']),

            earnedAmount () {
                if (this.completeRewards.promocodeReward > 0) {
                    return parseInt(this.baseReward) + parseInt(this.trueUpReward) + parseInt(this.completeRewards.promocodeReward);
                }
                else {
                    return parseInt(this.baseReward) + parseInt(this.trueUpReward) + parseInt(this.promocodeReward);
                }
            },

            totalAmount () {
                if (this.completeRewards.totalReward > 0) {
                    return parseInt(this.completeRewards.totalReward);
                }
                else {
                    return parseInt(this.totalReward);
                }
            },

            brandCopy () {
                return this.t('product_intake:product_supply');
            },

            prepaidCard () {
                let copy = this.t('global:prepaid_card');
                // This is for the front end tests. Will need to be refactored;
                copy = (typeof copy === 'string') ? { default: {} } : copy;
                const { card_format = {} } = (copy[this.app.subPhase]) || (copy.default);
                const { first_card_mention = '', second_card_mention = '', following_card_mentions = '' } = card_format;
                return { first_card_mention, second_card_mention, following_card_mentions };
            },
        },

        methods: {
            getSupplyCopy () {
                return this.t(`supply_amount.${this.supplies.supplyLength}`);
            },
        },
    };
