
    import { useI18next } from '@composables/i18next';
    import ImageIcon from './ImageIcon.vue';

    export default {

        name: 'QuestionsIcon',

        components: { ImageIcon },

        props: {
            circleColorClass: {
                type: String,
                default: 'darkBlue',
            },
            fillColor: {
                type: String,
                default: '#ffffff',
            },
            strokeColor: {
                type: String,
                default: '#ffffff',
            },
        },

        setup () {
            const { t } = useI18next(['buttons']);
            return { t };
        },
    };
