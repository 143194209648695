import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal-overlay" }
const _hoisted_2 = {
  class: "modal scandit-error",
  role: "dialog"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "manual-option" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ScanHeadlineTooltip = _resolveComponent("ScanHeadlineTooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        innerHTML: $setup.t('scan_error_modal.headline')
      }, null, 8, _hoisted_3),
      ($props.error === 'not_found')
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
            _createTextVNode(_toDisplayString($setup.t('scan_error_modal.not_found.subhead', { eye: $setup.t(`scan_error_modal.eye.${$props.eye}`) })) + " ", 1),
            _createVNode(_component_ScanHeadlineTooltip)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        innerHTML: $setup.t(`scan_error_modal.${$props.error}.body`)
      }, null, 8, _hoisted_5),
      _createElementVNode("a", {
        class: "btn",
        "data-e2e": "error-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.trackAndClose({ label: 'error_scan_again', type: 'rescan' })), ["prevent"]))
      }, _toDisplayString($setup.t('scan_error_modal.scan_button')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h3", {
          innerHTML: $setup.t('scan_error_modal.manual_entry.headline')
        }, null, 8, _hoisted_7),
        _createElementVNode("p", {
          innerHTML: $setup.t('scan_error_modal.manual_entry.body')
        }, null, 8, _hoisted_8),
        _createElementVNode("a", {
          href: "#",
          class: "btn",
          "data-e2e": "error-modal-close",
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.trackAndClose({ label: 'error_add_manually', type: 'manual' })), ["prevent"])),
          innerHTML: $setup.t('scan_error_modal.manual_entry.manual_button')
        }, null, 8, _hoisted_9)
      ])
    ])
  ]))
}