
    import { useI18next } from '@composables/i18next';
    import BaseTooltip from './BaseTooltip.vue';

    export default {
        components: {
            BaseTooltip,
        },

        setup () {
            const { t } = useI18next(['eye_care_provider_search']);
            return { t };
        },
    };
