
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'SampleReceiptModal',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },
    };
