import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["alt"]
const _hoisted_5 = {
  key: 1,
  class: "fal fa-times"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay sample-photo-modal",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.$i18nAsset('img/MAR_SampleReceipt.png'),
        alt: $setup.t('sample_receipt_alt')
      }, null, 8, _hoisted_2),
      _createElementVNode("p", {
        innerHTML: $setup.t('product_preview_modal.receipt_copy')
      }, null, 8, _hoisted_3),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        (_ctx.isDiamondPlus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "icon-close",
              alt: $setup.t('buttons:close')
            }, null, 8, _hoisted_4))
          : (_openBlock(), _createElementBlock("i", _hoisted_5))
      ])
    ])
  ]))
}