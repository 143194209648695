
    import { mapState, mapMutations } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import openModal, { NonParticipatingModal } from '../../modals/';
    import BaseTooltip from './BaseTooltip.vue';

    export default {
        components: {
            BaseTooltip,
        },

        emits: ['closeModal'],

        setup () {
            const { t } = useI18next(['patient_entry']);
            return { t };
        },

        computed: {
            ...mapState(['app']),
            disclaimer () {
                const copy = this.t('patient_entry:tooltip.not_listed.disclaimer');

                return copy.unaffiliated_ecp_flag;
            },
        },

        methods: {
            ...mapMutations({
                updateInfo: 'patient/updateInfo',
            }),
            openNonParticipationModal () {
                this.$emit('closeModal');
                openModal(NonParticipatingModal);
                this.scrollToTop();
            },
            updateCannotFind () {
                this.updateInfo({ cannot_find: true });
                this.$emit('closeModal');
            },
        },
    };
