
    import axios from 'axios';
    import { impersonationComment as validatorGuards } from '@lib/validation/client-validators';
    import { useI18next } from '@composables/i18next';
    import Validator from '@components/Validator';
    import BaseModal from './BaseModal';

    export default {
        name: 'CommentAdminModal',

        components: {
            Validator,
        },

        extends: BaseModal,

        props: {
            submissionId: {
                type: String,
                default: '',
            },
        },

        setup () {
            const { t } = useI18next(['modal_content']);
            return { t };
        },

        data () {
            return {
                additionalComments: '',
                isInvalid: false,
                validatorGuards,
            };
        },

        methods: {
            async postComment () {
                this.isInvalid = false;

                await this.$refs.validator.validate();

                if (!this.$refs.validator.isValid) {
                    this.isInvalid = true;
                    // Don't continue submitting.
                    return;
                }

                axios.post('/admin/api/contest/entry/' + this.submissionId + '/comments', { comment: this.additionalComments })
                    .then((response) => {
                        if (response.data.success == true) {
                            this.additionalComments = '';
                        }
                        else {
                            // message: 'Comments were not added successfully, please try again later.',
                        }

                        window.location = `${this.app.siteURL}admin#/purchase-validation/searchBy/entryId/${this.submissionId}`;
                        return;
                    })
                    .catch((error) => {
                        this.isInvalid = true;
                        console.log('Error comments entry!');
                        return;
                    });
            },
        },
    };
