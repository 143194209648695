import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal general-modal dp-modal",
  role: "dialog"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["alt"]
const _hoisted_5 = {
  key: 1,
  class: "fal fa-times"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.headline)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString($props.headline), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", { innerHTML: $props.body }, null, 8, _hoisted_3),
      _createElementVNode("a", {
        class: "close-button",
        "data-e2e": "modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }),
      ($props.cta && $props.link)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: `/${$props.link}`,
            class: "btn with-right-arrow",
            "data-e2e": "general-modal-close",
            onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal({ fromCTA: true })), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.cta), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : ($props.cta)
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              href: "#",
              class: "btn with-right-arrow",
              "data-e2e": "general-modal-close",
              onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal({ fromCTA: true })), ["prevent"]))
            }, _toDisplayString($props.cta), 1))
          : _createCommentVNode("", true),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        (_ctx.isDiamondPlus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "icon-close",
              alt: $setup.t('buttons:close')
            }, null, 8, _hoisted_4))
          : (_openBlock(), _createElementBlock("i", _hoisted_5))
      ])
    ])
  ]))
}