import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = ["alt"]
const _hoisted_2 = {
  key: 1,
  class: "fal fa-times"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_RewardEstimator = _resolveComponent("RewardEstimator")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal", `${$data.modalName}-modal`]),
      role: "dialog"
    }, [
      _createVNode(_component_RewardEstimator, {
        submissions: $props.submissionCount > 0,
        onClose: $options.closeRewardEstimator
      }, null, 8, ["submissions", "onClose"]),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        (_ctx.isDiamondPlus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "icon-close",
              alt: $setup.t('buttons:close')
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("i", _hoisted_2))
      ])
    ], 2)
  ]))
}