
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import OutgoingAffiliate from '@components/OutgoingAffiliate.vue';
    import { openPopup } from '../popup';
    import LanguageToggle from './LanguageToggle.vue';
    export default {
        name: 'TheFooter',

        components: {
            LanguageToggle,
            OutgoingAffiliate,
        },

        setup () {
            const { t } = useI18next(['footer', 'disclaimer', 'links', 'global', 'buttons', 'copy_clearance']);
            return { t };
        },

        data () {
            return {
                shouldDisplayLogout:
                    [
                        document.location.href.match(/rewards.promo.acuvue.com/),
                        document.location.href.match(/acuvue-mar.promo.eprize.com/),
                    ].filter((item) => item).length === 0,
            };
        },

        computed: {
            ...mapState(['app', 'profile', 'ui', 'purchase']),
            ...mapGetters({
                availableProfileMilestones: 'profile/availableProfileMilestones',
                inMilestoneProgram: 'patient/inMilestoneProgram',
            }),

            currentYear () {
                return new Date(this.app.now).getFullYear();
            },

            isLoggedIn () {
                return this.profile.id;
            },

            faqRoute () {
                const faqModes = {
                    preferred: this.purchase.hasPreferredEcp,
                };
                let routeMode = '';
                for (const mode in faqModes) {
                    if (faqModes[mode]) {
                        routeMode += routeMode === '' ? '/' : '_';
                        routeMode += mode;
                    }
                }
                return `#/faq${routeMode}`;
            },

            preferredRoute () {
                const preferredModes = {
                    preferredRewardsTerms: this.purchase.hasPreferredEcp,
                };
                let routeMode = '';
                for (const mode in preferredModes) {
                    if (preferredModes[mode]) {
                        routeMode += routeMode === '' ? '' : '_';
                        routeMode += mode;
                    }
                    else {
                        routeMode += 'rewardsTerms';
                    }
                }
                return `#/${routeMode}`;
            },

            copyClearance () {
                const
                    { subPhase } = this.app,
                    { pageName } = this.ui;
                // Default to the National Program (MAR) site Copy Clearance
                let copyClearanceNode = 'national_rewards.site';

                switch (pageName) {
                    case 'faq':
                        copyClearanceNode = 'national_rewards.faq';
                        break;
                    case 'terms-and-conditions':
                        copyClearanceNode = 'terms_and_conditions';
                        break;
                    case 'escalate':
                    case 'escalation-thanks':
                        copyClearanceNode = 'escalation_tool';
                        break;
                }
                return this.t(`${copyClearanceNode}.${subPhase}`) ||  this.t(`${copyClearanceNode}.default`);
            },

            hideToggle () {
                const pagesToHide = ['escalate', 'escalation-thanks'];
                return pagesToHide.includes(this.ui.pageName);
            },

            version () {
                const options = {
                    copyrightCopy: this.t('disclaimer:copyright', { currentYear: new Date().getFullYear() }),
                    publisher_info: this.t('acuvue_published_copy'),
                    currentYear: this.currentYear,
                    additionalDisclaimer: '',
                    interpolation: { escapeValue: false },
                };
                const copy = this.t('disclaimer:national_footer.disclaimer', options);

                return  (copy[this.app.subPhase]) || copy.default;
            },

            inIframe () {
                try {
                    return window.self !== window.top;
                }
                catch (e) {
                    return true;
                }
            },
        },

        methods: {
            openPopup,
            ...mapActions({
                logOut: 'profile/logOut',
            }),
            async logoutClicked () {
                await this.logOut();
                this.$router.replace('/login');
            },
        },
    };
