import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  width: "100%",
  height: "100%",
  controls: ""
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal video-modal", `${$props.pageName}-modal`]),
      role: "dialog"
    }, [
      _createElementVNode("video", _hoisted_1, [
        _createElementVNode("source", {
          src: `//d1hyi36gq7mg0j.cloudfront.net/acuvue/mar/media/${$props.pageName}.mp4`,
          type: "video/mp4"
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "icon-close",
          alt: $setup.t('buttons:close')
        }, null, 8, _hoisted_3)
      ])
    ], 2)
  ]))
}