
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';
    import openModal, { NonParticipatingModal } from './index';

    export default {
        name: 'DoctorNotListedModal',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['modal_content', 'links']);
            return { t };
        },

        data () {
            return {
                modalName: 'doctornotlisted',
            };
        },

        methods: {
            openClickHere (event) {
                event.preventDefault();

                if (event.target.tagName === 'A') {
                    this.closeModal();
                }
            },
            openNonParticipating (event) {
                event.preventDefault();
                if (event.target.tagName === 'A') {
                    this.closeModal();
                    openModal(NonParticipatingModal);
                    this.scrollToTop();
                }
            },
        },
    };
