
export function openPopup (event) {
    if (!event.target.href) return;
    if (event instanceof Event) {
        event.preventDefault();
    }
    const popupWindow = window.open(
        event.target.href,
        event.target.title,
        'menu=no,toolbar=no,width=768,height=600,scrollbars=1,resizable=0,' +
                    'directories=no,location=no,screenX=0,screenY=0,top=48,left=48',
    );
    if (popupWindow) {
        popupWindow.focus();
    }
}

// FIXME: do we ever use this form any more?
export function openExternal (event) {
    const popupWindow = window.open(
        event.target.href,
        event.target.title,
        'toolbar=yes,status=yes,location=yes,menubar=yes,directories=yes,' +
                    'resizable=yes,scrollbars=yes',
    );
    if (popupWindow) {
        popupWindow.focus();
    }
}
