import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import RSCDBackend from '../../../lib/rs-cd-backend';
import directive from './directive';

const resources = {
    en: { buttons: require('./en/buttons.json') },
    fr: { buttons: require('./fr/buttons.json') },
};

const initOpts = {"preload":["en","es"],"debug":false,"defaultNS":"global","fallbackLng":["en"],"returnNull":true,"returnObjects":true,"ns":["global","links","share"],"detection":{"lookupQuerystring":"locale","caches":false},"interpolation":{"skipOnVariables":true}};

export const initPromise = i18next
.use(RSCDBackend)
.use(LanguageDetector)
.init({
    ...initOpts,

    resources,
    // Load additional resource bundles from backend.
    partialBundledLanguages: true,
    // Use empty string for missing keys on the front-end.
    parseMissingKeyHandler: () => '',
    // parseMissingKeyHandler: (key) => `[Missing: ${key}]`,
    // debug: true,
});

export { i18next, directive as i18nextDirective };
