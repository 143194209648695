
import axios from 'axios';
import  { reactive } from 'vue';
import Cookies from 'js-cookie';
// We use this, rather than the full `jsonwebtoken` package,
// b/c we don't need and can't do any verification client-side
// anyway, and we save a bunch of huge encryption dependencies
// by skipping all that.
import jwtDecode from 'jwt-decode';
import { CookieParameters } from '@utilities';
import termsSubPhase from '@utilities/terms-sub-phase';
import config from 'rocketship-config';
import api from '../../api';

const SESSION_PERSISTENCE_ENABLED = true;

const sessionStorageKey = 'hw-auth';
const regCookieSuffix = ':registeredUser';
const newUserCookieSuffix = ':newUser';
const onboardingCookieSuffix = '_hide_onboarding';

const getDefaultState = () => reactive({
    token: getLocalStorageToken(),
    is_limited: true,
    plays_remaining: 0,
});

const cleanProfile = (profile) => {
    if ('g-recaptcha-response' in profile) {
        // Remove from returned state
        delete profile['g-recaptcha-response'];
    }
    // Remove empty token to prevent overwriting a valid one.
    if ('token' in profile && !profile.token) {
        delete profile.token;
    }

    return profile;
};

const state = getDefaultState();

const browserTime = makeObservableBrowserTime();

updateAxiosToken(state.token);

const getters = {
    loggedIn: (state) => 'id' in state && state.id && state.id !== 'me',

    tokenExpirationTime: (state) => {
        if (state.token) {
            const { exp } = jwtDecode(state.token);
            // UTC timestamp in seconds.
            return exp;
        }
        return;
    },
    isSessionExpired: (state, getters, rootState) => {
        if (!getters.loggedIn) return;

        const
            currentTime = browserTime.now / 1000,
            { tokenExpirationTime } = getters;

        return tokenExpirationTime <= currentTime;
    },
    returning: (state, getters, rootState) => Cookies.get(rootState.app.name + regCookieSuffix),
    newUser: (state, getters, rootState) => Cookies.get(rootState.app.name + newUserCookieSuffix),
    isAdminImpersonation: (state) => {
        if (state.token) {
            const { act } = jwtDecode(state.token);
            // JWT "actor" claim means this profile is being impersonated.
            return !!act;
        }
        return;
    },
    availableProfileMilestones: (state, getters, rootState) => {
        const programs = state.activePrograms;
        return (!programs) ? [] : termsSubPhase.getCurrentMilestones(rootState.app.now).filter((milestone) => !!programs[milestone]);
    },
};

const mutations = {
    resetProfile (state) {
        // Remove everything already in state.
        for (const key in state) {
            delete state[key];
        }

        // Re-set any default keys.
        const defaultState = getDefaultState();
        for (const key in defaultState) {
            state[key] = defaultState[key];
        }
    },

    // data can be an array (to prevent having to commit multiple mutations in
    // a row), or a flat object
    updateProfile (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }


        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];

                if (key === 'token') {
                    const { token } = state;

                    if (token) {
                        setLocalStorageToken(token);
                    }

                    updateAxiosToken(token);
                }
            }
        }
    },
};

const actions = {
    async loadSession ({ state, dispatch, rootState }) {
        if (state.token) {
            dispatch('setRegisteredUserCookie');

            try {
                await dispatch('getProfile');
            }
            catch (err) {
                // Kick the user back to intro to log in again.
                // FIXME: we can do better than a page refresh.
                window.location.href = rootState.app.siteURL;
            }
        }
    },

    async getProfilePrograms ({ getters, commit, dispatch }) {
        if (!getters.loggedIn) return;
        try {
            const { data: { programs } } = await axios.get(`${api.base}/purchase-submissions/profile/programs`);
            commit('updateProfile', { activePrograms: programs });
        }
        catch (err) {
            console.error(
                err.message,
                err,
            );

            throw err;
        }
    },

    getProfile ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'profiles/me',
        });
    },

    logIn ({ dispatch }, { tempProfile }) {
        return dispatch('makeCall', {
            endpoint: 'profiles/login',
            tempProfile,
        });
    },

    logOut ({ dispatch, commit }) {
        removeLocalStorageToken();
        dispatch('removeNewUserCookie');
        commit('resetProfile');
    },

    async register ({ dispatch }, { tempProfile }) {
        dispatch('setNewUserCookie');
        const response = await dispatch('makeCall', {
            endpoint: 'profiles',
            tempProfile,
        });

        return response;
    },

    async makeCall ({ state, dispatch, commit }, {
        type = 'post',
        endpoint,
        tempProfile = {},
    }) {
        commit('resetProfile');

        try {
            commit('updateProfile', tempProfile);
            const response = await axios[type](`${api.base}/${endpoint}`, state);
            const profile = cleanProfile(response.data.result.profile);
            commit('updateProfile', profile);

            if (profile.token) {
                dispatch('setRegisteredUserCookie');
            }

            return response;
        }
        catch (err) {
            dispatch('logOut');

            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            throw err;
        }
    },

    setRegisteredUserCookie ({ rootState }) {
        Cookies.set(rootState.app.name + regCookieSuffix, 'yes', CookieParameters.getCookieParams());
    },

    setNewUserCookie ({ rootState }) {
        Cookies.set(rootState.app.name + newUserCookieSuffix, 'yes', CookieParameters.getCookieParams());
    },

    removeNewUserCookie ({ rootState }) {
        Cookies.remove(rootState.app.name + newUserCookieSuffix, CookieParameters.getCookieParams());
    },
    setOnboardingCookie ({ rootState }) {
        Cookies.set(rootState.app.name + onboardingCookieSuffix, CookieParameters.getCookieParams());
    },
};

// Send custom auth header with every AJAX request.
function updateAxiosToken (token) {
    if (token) {
        // Not using Authorization header due to review's Basic auth.
        axios.defaults.headers.common['X-HW-Profile-Token'] = token;
    }
    else {
        delete axios.defaults.headers.common['X-HW-Profile-Token'];
    }
}

function getLocalStorageToken () {
    if (!SESSION_PERSISTENCE_ENABLED) return;

    try { return window.localStorage.getItem(sessionStorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

function setLocalStorageToken (token) {
    if (!SESSION_PERSISTENCE_ENABLED) return;

    try { window.localStorage.setItem(sessionStorageKey, token); }
    catch (err) { console.error('localStorage error', err); }
}

function removeLocalStorageToken () {
    try { window.localStorage.removeItem(sessionStorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

// As opposed to `app/now`, which is server time at initial page load.
function makeObservableBrowserTime () {
    // Tick the clock once every second.
    const TICK_INTERVAL = 1000;

    const observableTime = reactive({
        now: Date.now(),
        // In case somebody wants to cancel this.
        intervalId: null,
    });

    observableTime.intervalId = setInterval(() => {
        observableTime.now = Date.now();
    }, TICK_INTERVAL);

    return observableTime;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
