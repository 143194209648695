
    export default {
        name: 'ImageIcon',
        props: {
            // Use a predefined background color class
            circleColorClass: {
                type: String,
                default: 'lightGray1',
            },
        },
    };
