
    import { useI18next } from '@composables/i18next';
    import { track } from '../plugins/WebAnalytics';

    export default {
        name: 'OutgoingAffiliate',

        props: {
            affiliateId: {
                type: String,
                required: true,
            },
            target: {
                type: String,
                default: null,
            },
            isButton: {
                type: Boolean,
                default: true,
            },
        },

        setup () {
            const { t } = useI18next(['buttons']);
            return { t };
        },

        computed: {
            href () {
                return `/redirect_to_affiliate?affiliate_id=${this.affiliateId}`;
            },
        },

        methods: {
            trackAndGo (event) {
                const
                    { affiliateId } = this,
                    { target } = this,
                    { href } = this,
                    trackingObject = {
                        category: 'Exit',
                        label: affiliateId,
                        value: 1,
                    };

                // Allow default behavior when we are opening in a new window.
                // This happens when target is `_blank`, or the user presses
                // CTRL+click, CMD+click, or middle click.
                if (
                    target === '_blank' ||
                    event?.ctrlKey || event?.meta || event?.which == 2
                ) {
                    track('Affiliate', trackingObject);
                    return true;
                }
                else {
                    track('Affiliate', trackingObject, () => {
                        switch (target) {
                            case '_top':
                                window.top.location.href = href;
                                break;
                            case '_parent':
                                window.parent.location.href = href;
                                break;
                            default:
                                window.location.href = href;
                        }
                    });
                    event?.preventDefault();
                    return false;
                }
            },
        },
    };
