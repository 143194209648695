
    import { useI18next } from '@composables/i18next';
    import RewardEstimator from '@components/RewardEstimator';
    import BaseModal from './BaseModal';

    export default {
        name: 'RewardEstimatorModal',

        components: {
            RewardEstimator,
        },

        extends: BaseModal,

        props: {
            headline: {
                type: String,
                default: '',
            },
            body: {
                type: String,
                default: '',
            },
            submissionCount: {
                type: Number,
                default: -1,
            },
        },

        setup () {
            const { t } = useI18next(['buttons']);
            return { t };
        },

        data () {
            return {
                modalName: 'reward-estimator',
            };
        },
        methods: {
            closeRewardEstimator () {
                this.closeModal();
                this.$router.replace('/patient_select');
            },
        },
    };
