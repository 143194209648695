
// This page component is not dynamically imported, which is one
// way of ensuring it's not split out into a separate async chunk.
import ErrorPage from '../views/ErrorPage.vue';
import RedirectPage from '../views/RedirectPage.vue';

export default [
    { path: '/error', component: ErrorPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
    { path: '/redirect-page', component: RedirectPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
];
