
    import { mapState } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import BaseModal from './BaseModal';

    export default {
        name: 'LoginModal',

        extends: BaseModal,

        setup () {
            const { t } = useI18next(['faq_modals']);
            return { t };
        },

        data () {
            return {
                modalName: 'login',
            };
        },

        computed: {
            ...mapState(['app']),
        },
    };
