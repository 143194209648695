
/* This is client-side code. */
/* eslint-env browser */

const
    { isFilled } = require('rocketship-validator'),
    base = require('./validators');

module.exports = {
    register: base.register,
    login: base.login,
    viral: base.viral,
    faqContact: base.faqContact,
    patient: base.patientInfo,
    confirmPurchaseSubmission: base.confirmPurchaseSubmission,
    ecpPostalAndAccount: base.eyeCareProfessionalSearchByPostalCodeAndAccountName,
    purchaseDate: base.purchaseDate,
    suppliesPurchased: base.suppliesPurchased,
    rewardEstimator: base.rewardEstimator,
    purchaseSKU: base.purchaseSKU,
    sms: base.sms,
    authCode: base.authCode,
    limitRemaining: base.limitRemaining,
    startSubmission: base.startSubmission,
    checkoutConfirm: base.checkoutConfirm,
    checkoutConfirmPatient: base.checkoutConfirmPatient,
    incompletePurchaseSubmission: base.incompletePurchaseSubmission,
    eyeCareProfessionalNotFound: base.eyeCareProfessionalNotFound,
    invoiceFileUpload: base.invoiceFileUpload,
    requiredPatientInfo: base.requiredPatientInfo,
    checkoutShopPatientInfo: base.checkoutShopPatientInfo,
    promocode: base.promocode,
    escalate: base.escalate,
    groupAffiliationSusLevel: base.groupAffiliationSusLevel,
    adminEmailTrigger: base.adminEmailTrigger,
    adminPromocodePromotion: base.adminPromocodePromotion,
    adminPromocodeClass: base.adminPromocodeClass,
    adminException: base.adminException,
    adminProfileExport: base.adminProfileExport,
    invoiceSupport: base.invoiceSupport,
    impersonationComment: base.impersonationComment,
};

function requireCaptcha (value, field, profile) {
    // Short-circuit where reCaptcha can't work anyway.
    if (
        // Don't require captcha on the client-side in test mode.
        profile.isAutomatedTest ||
        // Don't require captcha client-side on `localhost`.
        // (running locally, likely in Docker)
        window.location.hostname === 'localhost'
    ) {
        return true;
    }

    if (!isFilled(value)) {
        // Error out early if we don't have a response.
        this.addError(field, 'NULL');
        return false;
    }
    return true;
}
