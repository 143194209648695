import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/icon-close-modal.svg'


const _hoisted_1 = {
  class: "modal confirm-purchase",
  role: "dialog"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "inner" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "inner" }
const _hoisted_6 = ["alt"]
const _hoisted_7 = {
  key: 1,
  class: "fal fa-times"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay general-modal",
    onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString($setup.t('oamoe_override.headline_copy')), 1),
      _createElementVNode("p", {
        innerHTML: $setup.t('oamoe_override.body_copy')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          "data-e2e": "oamoe_override-modal-close",
          onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.navigateToLogIncomplete && $options.navigateToLogIncomplete(...args)), ["prevent"]))
        }, _toDisplayString($setup.t('oamoe_override.button_copy.log_incomplete')), 1)
      ]),
      _createElementVNode("p", {
        innerHTML: $setup.t('oamoe_override.body_copy_2')
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          "data-e2e": "oamoe_override-modal-close",
          onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.continueSubmission && $options.continueSubmission(...args)), ["prevent"]))
        }, _toDisplayString($setup.t('oamoe_override.button_copy.continue')), 1)
      ]),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "modal-close",
        class: "close-btn",
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, [
        (_ctx.isDiamondPlus)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              class: "icon-close",
              alt: $setup.t('buttons:close')
            }, null, 8, _hoisted_6))
          : (_openBlock(), _createElementBlock("i", _hoisted_7))
      ])
    ])
  ]))
}