import axios from 'axios';

export const api = axios.create({
    baseURL: '/api/auth/',
});

export default {
    async sendCode ({ phone_number, token }) {
        try {
            const data = {
                phone_number,
                token,
            };
            const response = await api.post('sendCode', data);
            return response;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    },
    async validateCode ({ code, token }) {
        try {
            const data = {
                code,
                token,
            };
            const response = await api.post('validateCode', data);
            if (!response.data.isValid) {
                throw new Error('This is not a valid code');
            }
            return response;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    },
};
